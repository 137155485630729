import * as React from "react"
import Layout from "../components/layout"
import "../styles/contact.css"

const ThankYouPage = () => {
    return (
        <Layout pageTitle="Peter Mason | Thank You!">
            <h1>Thank You!</h1>

        </Layout>
    )
}

export default ThankYouPage

